import { useState, useCallback, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";

export type FilterState = {
  keyword?: string;
  jobType?: string;
  workingHours: string[];
  features: string[];
  showShortVideos: boolean;
};

type UseSearchProps = {
  onSearch: (query: string) => void;
  onFilterChange?: (filters: FilterState) => void;
  initialFilters?: FilterState;
  initialSearchQuery?: string;
};

export function useSearch({
  onSearch,
  onFilterChange,
  initialFilters,
  initialSearchQuery = "",
}: UseSearchProps) {
  const [filters, setFilters] = useState<FilterState>(
    initialFilters ?? {
      keyword: initialSearchQuery,
      workingHours: [],
      features: [],
      showShortVideos: false,
    }
  );

  const handleImmediateSearch = useCallback(
    (filters: FilterState) => {
      const searchParams = new URLSearchParams();

      if (filters.keyword) searchParams.set("q", filters.keyword);
      if (filters.jobType) searchParams.set("jobType", filters.jobType);
      if (filters.workingHours.length) {
        searchParams.set(
          "workingHours",
          filters.workingHours.map(encodeURIComponent).join(",")
        );
      }
      if (filters.features.length) {
        searchParams.set(
          "features",
          filters.features.map(encodeURIComponent).join(",")
        );
      }
      if (filters.showShortVideos) searchParams.set("showShorts", "true");

      onSearch(searchParams.toString());
    },
    [onSearch]
  );

  const debouncedSearch = useDebouncedCallback((newFilters: FilterState) => {
    handleImmediateSearch(newFilters);
  }, 300);

  const handleFilterChange = useCallback(
    (newFilters: Partial<FilterState>) => {
      setFilters((prev) => {
        const updatedFilters = { ...prev, ...newFilters };
        onFilterChange?.(updatedFilters);

        if ("keyword" in newFilters) {
          debouncedSearch(updatedFilters);
        } else {
          handleImmediateSearch(updatedFilters);
        }

        return updatedFilters;
      });
    },
    [onFilterChange, debouncedSearch, handleImmediateSearch]
  );

  return {
    filters,
    handleFilterChange,
    handleImmediateSearch,
    debouncedSearch,
  };
}
